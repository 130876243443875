import { FiX } from "react-icons/fi";
import logoImg from "img/logo_GMY_v2.svg";
import { t } from "@lingui/macro";
import "./Header.scss";
import { Link } from "react-router-dom";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { IconArrow, IconDiscord, IconLinkTree, IconLogo, IconMedium, IconTelegram, IconX } from "assets";
import { ReactNode, useState } from "react";
import styled from "styled-components";
import { linkSocial } from "constants/constants";

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
};

type MenuItem = {
  label: string;
  link?: string;
  icon?: ReactNode;
  isTargetSelf?: boolean;
  children?: MenuItem[];
};

export function HomeHeaderLinks({ small, clickCloseIcon }: Props) {
  const HOME_MENUS: MenuItem[] = [
    {
      label: `Perp`,
      link: "https://app.yummy.fi/#/trade",
    },
    {
      label: `Analytics`,
      link: "#",
    },
    {
      label: `Resources`,
      link: "#",
    },
    {
      label: `Social`,
      children: [
        {
          label: "Linktree",
          link: linkSocial.LINKTREE,
          icon: (
            <IconLinkTree
              fill={small ? "rgba(255, 255, 255, 0.6)" : "#fff"}
              width={small ? "20" : "24"}
              height={small ? "20" : "24"}
            />
          ),
        },
        {
          label: "X",
          link: linkSocial.X,
          icon: (
            <IconX
              fill={small ? "rgba(255, 255, 255, 0.6)" : "#fff"}
              width={small ? "20" : "24"}
              height={small ? "20" : "24"}
            />
          ),
        },
        {
          label: "Medium",
          link: linkSocial.MEDIUM,
          icon: (
            <IconMedium
              fill={small ? "rgba(255, 255, 255, 0.6)" : "#fff"}
              width={small ? "20" : "24"}
              height={small ? "20" : "24"}
            />
          ),
        },
        {
          label: "Telegram",
          link: linkSocial.TELEGRAM,
          icon: (
            <IconTelegram
              fill={small ? "rgba(255, 255, 255, 0.6)" : "#fff"}
              width={small ? "20" : "24"}
              height={small ? "20" : "24"}
            />
          ),
        },
      ],
    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [openIndex, setOpenIndex] = useState<number | null>(null); // Add state for mobile dropdown

  const handleMenuClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle dropdown
  };

  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <Link className="App-header-link-main" to="/">
            <IconLogo />
          </Link>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}
      <div className="nav-landing">
        {HOME_MENUS.map((menuItem, index) => (
          <div
            key={menuItem.label}
            className={`App-header-link-container head-landing ${openIndex === index ? "open" : ""}`}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            style={{ position: "relative" }}
          >
            {menuItem.link ? (
              <ExternalLink href={menuItem.link}> {menuItem.label} </ExternalLink>
            ) : (
              // <NavItem isDisable={menuItem.link === "#"}>{menuItem.label}</NavItem>
              <ExtendBoxMb>
                <div onClick={() => handleMenuClick(index)} className="title">
                  {menuItem.label}{" "}
                  {menuItem.children && (
                    <IconArrow fill="rgba(255, 255, 255, 0.6)" status="down" width="18" height="18" />
                  )}
                </div>
                {small && menuItem.children && openIndex === index && (
                  <div className="box-child">
                    {menuItem.children.map((childItem) => (
                      <>
                        {childItem.link === "" ? (
                          <a
                            style={{
                              cursor: "not-allowed",
                            }}
                            className="child-item-mb"
                            key={childItem.label}
                          >
                            {childItem.icon} {childItem.label}
                          </a>
                        ) : (
                          <a className="child-item-mb" key={childItem.label} target="_blank" href={childItem.link}>
                            {childItem.icon} {childItem.label}
                          </a>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </ExtendBoxMb>
            )}
            {!small && menuItem.children && hoveredIndex === index && (
              <ChildBox>
                {menuItem.children.map((childItem) => (
                  <>
                    {childItem.link === "" ? (
                      <div
                        key={childItem.label}
                        style={{
                          cursor: "not-allowed",
                        }}
                        className="dropdown-item"
                      >
                        {childItem.icon}
                        {childItem.label}
                      </div>
                    ) : (
                      <ExternalLink
                        key={childItem.label}
                        target="_blank"
                        href={childItem.link ?? ""}
                        className="dropdown-item"
                      >
                        {childItem.icon}
                        {childItem.label}
                      </ExternalLink>
                    )}
                  </>
                ))}
              </ChildBox>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const NavItem = styled.a<{ isDisable: boolean }>`
  cursor: ${(props) => (props.isDisable ? "not-allowed !important" : "pointer !important")};
  :hover {
    ${(props) =>
      !props.isDisable &&
      `
      color: #6BA9FC !important;
    `}
  }
`;

const ExtendBoxMb = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding: 0;
  width: 100%;
  .title {
    cursor: pointer;
    display: flex;
    align-items: center;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }
  @media (max-width: 1351px) {
    line-height: 1.8rem;
    font-weight: normal;
    letter-spacing: 0.1px;
    color: rgba(255, 255, 255, 0.6);
    padding: 1.8rem 1.15rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    align-items: start;
    .title {
      justify-content: space-between;
      width: 100%;
    }
    .box-child {
      margin-top: 8px;
      width: 100%;
    }
    .child-item-mb {
      width: 100% !important;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 10px !important;
      height: fit-content !important;
      :hover {
        background-color: transparent !important;
      }
    }
  }
`;

const ChildBox = styled.div`
  position: absolute;
  top: 140%;
  left: 0;
  background-color: #33302e;
  border-radius: 16px;
  z-index: 10;
  padding: 8px;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  ::after {
    content: "";
    position: absolute;
    height: 16px;
    width: 100%;
    top: -10px;
    left: 0;
  }
  .dropdown-item {
    padding: 12px !important;
    min-width: 200px;
    text-align: left;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    align-items: center;
    border-radius: 12px;
    gap: 12px;
    &:hover {
      background-color: #242323;
    }
  }
`;
